import { useContext, useMemo } from 'react';
import { HeaderMenu } from 'api_entities/strapi/types';
import { HeaderMenuContext } from '../context/HeaderMenuContext/HeaderMenuContext';

export type HeaderMenuType = Pick<HeaderMenu['attributes'], 'menu' | 'submenu'>;

export const parseMenuData = (headerMenu: HeaderMenu, filterBy?: string) => {
  const { menu, submenu } = headerMenu.attributes;

  if (filterBy) {
    const _menu = menu.filter((item) => item.templateType === filterBy);

    return { submenu, menu: _menu };
  }

  return { menu, submenu };
};

export const useHeaderMenu = (filterByTemplateType?: string): HeaderMenuType => {
  const context = useContext(HeaderMenuContext);

  if (typeof context === 'undefined') {
    throw new Error('useHeaderMenu must be used within a HeaderMenuContext provider.');
  }

  return useMemo(() => {
    return parseMenuData(context, filterByTemplateType);
  }, [context, filterByTemplateType]);
};
