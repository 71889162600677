import { APP_ROUTES } from 'constants/routes';

import { memo, useState } from 'react';
import Lottie from 'lottie-react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import Image from 'next/image';
import Cookie from 'js-cookie';
import { useQueryClient } from 'react-query';
import { useRouter } from 'next/router';
import { Button, Icons } from '@studenten/ui-components';
import { MenuDropdown } from 'features/layout/templates/Layout/components/Header/components/MenuDropdown';
import { COOKIE } from 'constants/cookie';
import { CACHE_KEYS } from 'constants/cache';
import { userApi } from 'api_entities/user';
import { isServer } from 'utils/helpers';
import { size } from 'constants/device';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { useHeaderMenu } from 'hooks/useHeaderMenu';
import { useProfile } from 'hooks/useProfile';
import { UserMenu } from 'src/components/UserMenu';
import animationData from './sign_in_animation.json';

import {
  Container,
  Navigation,
  CategoriesContainer,
  CategoriesLinkWrapper,
  CategoriesList,
  Content,
  LoginLink,
  SignUpLink,
  AuthButtonsContainer,
  LogoLink,
  MobileMenuButton,
} from './Header.styled';

const MobileMenu = dynamic(() => import('features/layout/components/MobileMenu'));

export const Header = memo(() => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const headerMenuData = useHeaderMenu('core');

  const { data: profileData, isLoading } = useProfile({
    onSuccess: async () => {
      const profile = await queryClient.fetchQuery(CACHE_KEYS.USER, userApi.getUser);
      const from = router.asPath;

      if (!profile.loginViaSocialNetwork && !profile.emailVerifiedAt) {
        await router.replace({
          pathname: APP_ROUTES.VERIFY_EMAIL,
          query: { email: profile.email, ...(from && { from }) },
        });
      } else if (!profile.onboardAt) {
        await router.replace({
          pathname: APP_ROUTES.ONBOARDING,
          query: { ...(from && { from }) },
        });
      }
    },
  });
  const isAuthenticated = !!profileData;

  const logout = () => {
    Cookie.remove(COOKIE.ACCESS_TOKEN);
    Cookie.remove(COOKIE.REFRESH_TOKEN);

    location.reload();
  };

  const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth < size.laptop;

  const isHomePage = router.pathname === APP_ROUTES.HOME;
  const logoSrc = isHomePage ? '/assets/logo-white.svg' : '/assets/logo.svg';

  return (
    <Container isDark={isHomePage}>
      {isMobile && headerMenuData && (
        <MobileMenu
          isAuthenticated={isAuthenticated}
          isOpen={isMobileMenuOpen}
          setOpen={setMobileMenuOpen}
          onLogoutClick={logout}
          headerMenuItems={headerMenuData}
        />
      )}
      <Content>
        <Link passHref href={APP_ROUTES.HOME} prefetch={false}>
          <LogoLink>
            {isMobile ? (
              <Image
                priority
                src={logoSrc}
                layout="fixed"
                width={109}
                height={28}
                alt="Studenten logo."
              />
            ) : (
              <Image
                priority
                src={logoSrc}
                layout="fixed"
                width={140}
                height={36}
                alt="Studenten logo."
              />
            )}
          </LogoLink>
        </Link>

        {!isMobile && (
          <>
            <Navigation>
              {headerMenuData &&
                headerMenuData.menu.map((menu) => {
                  return (
                    <CategoriesContainer key={menu.id}>
                      <CategoriesLinkWrapper isDark={!isHomePage}>
                        <p>
                          {menu.href ? (
                            <Link href={menu.href} prefetch={false} passHref>
                              <a>{menu.label}</a>
                            </Link>
                          ) : (
                            menu.label
                          )}
                        </p>
                      </CategoriesLinkWrapper>
                      <CategoriesList>
                        <MenuDropdown menuItems={menu.items} subItems={headerMenuData.submenu} />
                      </CategoriesList>
                    </CategoriesContainer>
                  );
                })}
            </Navigation>
            <AuthButtonsContainer>
              {isServer() || isLoading ? null : !isAuthenticated ? (
                <>
                  <Link passHref href={APP_ROUTES.LOGIN} prefetch={false}>
                    <LoginLink>Log In</LoginLink>
                  </Link>
                  <Link passHref href={APP_ROUTES.SIGN_UP} prefetch={false}>
                    <SignUpLink>
                      Inschrijven <Lottie loop animationData={animationData} />
                    </SignUpLink>
                  </Link>
                </>
              ) : (
                <UserMenu onLogoutClick={logout} />
              )}
            </AuthButtonsContainer>
          </>
        )}

        {isMobile && (
          <MobileMenuButton contrast={isHomePage}>
            <Button variant="base" onClick={() => setMobileMenuOpen(true)}>
              <Icons.Burger />
            </Button>
          </MobileMenuButton>
        )}
      </Content>
    </Container>
  );
});
